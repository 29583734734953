@import './variables.scss';

@import-normalize;

html, body {
    margin: 0;
    padding: 0;
}

html, body, #root {
    height: 100%;
}
